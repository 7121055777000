import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/dashboard/Dashboard.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Dashboard',
        component: Home
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: () =>
            import ('../views/dashboard/Dashboard.vue')
    },
    {
        path: '/articlesfamille',
        name: 'ArticlesFamille',
        component: () =>
            import ('../views/ArticlesFamille/ArticlesFamille.vue')
    },
    {
        path: '/articles',
        name: 'Articles',
        component: () =>
            import ('../views/Articles/Articles.vue')
    },
    {
        path: '/fournisseurs',
        name: 'Fournisseurs',
        component: () =>
            import ('../views/Fournisseurs/Fournisseurs.vue')
    },
    {
        path: '/clients',
        name: 'Clients',
        component: () =>
            import ('../views/Clients/Clients.vue')
    },
    {
        path: '/magasin',
        name: 'Magasin',
        component: () =>
            import ('../views/Magasin/Magasin.vue')
    },
    {
        path: '/pointdevente',
        name: 'PointVente',
        component: () =>
            import ('../views/PointVente/PointVente.vue')
    },
    {
        path: '/inventaire',
        name: 'Inventaire',
        component: () =>
            import ('../views/Inventaire/Inventaire.vue')
    },
    {
        path: '/inventaire/:type',
        name: 'Inventaireadd',
        component: () =>
            import ('../views/Inventaire/Inventaire.vue')
    },
    {
        path: '/approvisionnement',
        name: 'Approvisionnement',
        component: () =>
            import ('../views/Approvisionnement/Approvisionnement.vue')
    },
    {
        path: '/approvisionnement/:type',
        name: 'Approvisionnementadd',
        component: () =>
            import ('../views/Approvisionnement/Approvisionnement.vue')
    },
    {
        path: '/utilisateurs',
        name: 'Users',
        component: () =>
            import ('../views/Users/Users.vue')
    },
    {
        path: '/bondecommande',
        name: 'BonCommande',
        component: () =>
            import ('../views/BonCommande/BonCommande.vue')
    },
    {
        path: '/bondecommande/:type',
        name: 'BonCommandeadd',
        component: () =>
            import ('../views/BonCommande/BonCommande.vue')
    },
    {
        path: '/bondelivraison',
        name: 'BonLivraison',
        component: () =>
            import ('../views/BonLivraison/BonLivraison.vue')
    },
    {
        path: '/bondelivraison/:type',
        name: 'BonLivraisonadd',
        component: () =>
            import ('../views/BonLivraison/BonLivraison.vue')
    },
    {
        path: '/factures',
        name: 'Factures',
        component: () =>
            import ('../views/Factures/Factures.vue')
    },
    {
        path: '/facturesproformat',
        name: 'Facturesproformat',
        component: () =>
            import ('../views/Factures/Factures.vue')
    },
    {
        path: '/facturesreglement',
        name: 'Facturesreglement',
        component: () =>
            import ('../views/Facturesreglement/Facturesreglement.vue')
    },
    {
        path: '/facturesreglement/parclient/:id',
        name: 'Facturesreglementparclient',
        component: () =>
            import ('../views/Facturesreglement/Facturesreglement.vue')
    },
    {
        path: '/facturesreglement/:id',
        name: 'Facturesreglementparfacture',
        component: () =>
            import ('../views/Facturesreglement/Facturesreglement.vue')
    },
    {
        path: '/factures/:type',
        name: 'Factures',
        component: () =>
            import ('../views/Factures/Factures.vue')
    },
    {
        path: '/depenses',
        name: 'Depenses',
        component: () =>
            import ('../views/Depenses/Depenses.vue')
    },
    {
        path: '/etatdepenses',
        name: 'EtatsDepenses',
        component: () =>
            import ('../views/EtatsDepenses/EtatsDepenses.vue')
    },
    {
        path: '/degats',
        name: 'Degats',
        component: () =>
            import ('../views/Degats/Degats.vue')
    },
    {
        path: '/etatsdegats',
        name: 'EtatsDegats',
        component: () =>
            import ('../views/EtatsDegats/EtatsDegats.vue')
    },
    {
        path: '/etatsclients',
        name: 'EtatsClients',
        component: () =>
            import ('../views/EtatsClients/EtatsClients.vue')
    },
    {
        path: '/transfertstock',
        name: 'Transfertstock',
        component: () =>
            import ('../views/Transfertstock/Transfertstock.vue')
    },
    {
        path: '/approvisionnementreglement',
        name: 'Approreglement',
        component: () =>
            import ('../views/Approreglement/Approreglement.vue')
    },
    {
        path: '/approvisionnementreglement/parfournisseur/:id',
        name: 'Approreglementparfournisseur',
        component: () =>
            import ('../views/Approreglement/Approreglement.vue')
    },
    {
        path: '/approvisionnementreglement/:id',
        name: 'Approreglementparappro',
        component: () =>
            import ('../views/Approreglement/Approreglement.vue')
    },
    {
        path: '/mouvements',
        name: 'Articlesmouvement',
        component: () =>
            import ('../views/Articlesmouvement/Articlesmouvement.vue')
    },
    {
        path: '/etatfournisseurs',
        name: 'EtatsFournisseurs',
        component: () =>
            import ('../views/EtatsFournisseurs/EtatsFournisseurs.vue')
    },
    {
        path: '/stockenvaleur',
        name: 'Stockenvaleur',
        component: () =>
            import ('../views/Stockenvaleur/Stockenvaleur.vue')
    },
    {
        path: '/ventesdetail',
        name: 'Ventesdetail',
        component: () =>
            import ('../views/Articlesdetail/Articlesdetail.vue')
    },
    {
        path: '/infosociete',
        name: 'Infosociete',
        component: () =>
            import ('../views/Infosociete/Infosociete.vue')
    },
    {
        path: '/bondelivraison/bondecommande/:id_bon_commande',
        name: 'BonLivraisongenerate',
        component: () =>
            import ('../views/BonLivraison/BonLivraison.vue')
    },
    {
        path: '/ventedirect',
        name: 'Ventedirect',
        component: () =>
            import ('../views/Ventedirect/Ventedirect.vue')
    },
    {
        path: '/ventedirectreglementna/:id',
        name: 'Ventedirectreglementna',
        component: () =>
            import ('../views/Ventedirectreglement/Ventedirectreglement.vue')
    },
    {
        path: '/ventedirectreglementfa/:id',
        name: 'Ventedirectreglementfa',
        component: () =>
            import ('../views/Ventedirectreglement/Ventedirectreglement.vue')
    },
    {
        path: '/ventedirectreglement/parclient/:id',
        name: 'Ventedirectreglementparclient',
        component: () =>
            import ('../views/Ventedirectreglement/Ventedirectreglement.vue')
    },
    {
        path: '/ventedirectreglement/parfournisseur/:id',
        name: 'Ventedirectreglementparfournisseur',
        component: () =>
            import ('../views/Ventedirectreglement/Ventedirectreglement.vue')
    },
    {
        path: '/bondecommandefournisseur',
        name: 'BonCommandeFournisseur',
        component: () =>
            import ('../views/BonCommandeFournisseur/BonCommandeFournisseur.vue')
    },
    {
        path: '/approvisionnement/bondecommande/:id_bon_commande',
        name: 'Approvisionnementgenerate',
        component: () =>
            import ('../views/Approvisionnement/Approvisionnement.vue')
    },
    {
        path: '/ventedirect/bondecommande/:id_bon_commande',
        name: 'Ventedirectgenerate',
        component: () =>
            import ('../views/Ventedirect/Ventedirect.vue')
    },
    {
        path: '/echeance',
        name: 'Echeance',
        component: () =>
            import ('../views/Echeance/Echeance.vue')
    },
    {
        path: '/sortiestock',
        name: 'Sortiestock',
        component: () =>
            import ('../views/Sortiestock/Sortiestock.vue')
    },
    {
        path: '/tresoreries',
        name: 'Tresoreries',
        component: () =>
            import ('../views/Tresoreries/Tresoreries.vue')
    },
    {
        path: '/mouvementsdecaisse',
        name: 'Tresoreriesdetail',
        component: () =>
            import ('../views/Tresoreriesdetail/Tresoreriesdetail.vue')
    },
    {
        path: '/reglementhistorique',
        name: 'Facturesreglementhirstorique',
        component: () =>
            import ('../views/Facturesreglementhirstorique/Facturesreglementhirstorique.vue')
    },
    {
        path: '/importdata',
        name: 'Import',
        component: () =>
            import ('../views/Import/Import.vue')
    },
    {
        path: '/clientreglements',
        name: 'Facturesreglementclient',
        component: () =>
            import ('../views/Facturesreglementclient/Facturesreglementclient.vue')
    },
    {
        path: '/clientfactures',
        name: 'Facturesclient',
        component: () =>
            import ('../views/Facturesclient/Facturesclient.vue')
    },

    // {
    //     path: '/factures',
    //     name: 'Factures',
    //     component: () =>
    //         import ('../views/Factures/Factures.vue')
    // },
    // {
    //     path: '/logout',
    //     name: 'Logout',
    //     component: () =>
    //         import ('../views/Logout/Logout.vue')
    // },
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router